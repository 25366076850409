import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  validatePassword,
  validateIsRequired,
  validateRepeatPassword,
  DEFAULT_VALIDATION,
} from '../../utils/validators.utils';
import { ConfirmationUserInfo, ConfirmationForm, ConfirmationErrorMessage } from '../../components/confirmation-form';
import { Box, makeStyles } from '@material-ui/core';
import LogoBanner from '../../components/common/LogoBanner.component';
import LoadingModal from '../../components/common/LoadingModal.component';
import { GET_REGISTRATION_REQUEST } from '../../queries';
import { SEND_REQUEST_REGISTRATION } from '../../mutations';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { goToIngresar } from '../../api/navigation';

const useStyle = makeStyles(
  (theme) => ({
    mainContainer: {
      [theme.breakpoints.down('sm')] : {
        padding: `0 ${theme.space.horizontal.mobile}`
      },
      [theme.breakpoints.up('md')]:{
        width:'60%',
        margin:'auto'
      }
    },
  })
);


const PHONE_VALIDATION_MESSAGE = 'El teléfono es requerido';
const DEFAULT_FIELDS = {
  phone: '',
  password: '',
  repeatPassword: '',
};
const DEFAULT_VALIDATIONS = {
  phone: DEFAULT_VALIDATION,
  password: DEFAULT_VALIDATION,
  repeatPassword:DEFAULT_VALIDATION
};
const ERROR_TITLE = '¡ Algo salió mal !';
const ERROR_MESSAGE = 'La solicitud de confirmación que intenta acceder no es válida';
const ConfirmationFormContainer = ({
  requestRegistrationId,
}) => {
  const [fields, setFields] = useState(DEFAULT_FIELDS);
  const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);
  const [formHasError, setFormHasError] = useState(false);
  const [formIsComplete, setFormIsComplete] = useState(false);
  const { data: userInfoData, loading: loadingQuery, error: errorQuery } = useQuery(GET_REGISTRATION_REQUEST,
    { variables: { requestRegistrationId } }
  );
  const [sendRequestRegistration,
    { loading: loadingRequest, error: errorRequest }] = useMutation(SEND_REQUEST_REGISTRATION,
    { onCompleted: () => {
      goToIngresar();
    },
    });
  const classes = useStyle();

  const handleFieldChange = (key, value)=> {
    const newFields = { ...fields };
    newFields[key] = value;
    setFields(newFields);
    resetValidationByKey(key);
  };

  const validateFields = (newFields) => {
    const newValidations = {
      ...validations
    };
    const passwrodValidation = validatePassword(newFields.password);
    const repeatPasswordValidation = validateRepeatPassword(newFields.password, newFields.repeatPassword);
    const phoneValidation = validateIsRequired(newFields.phone, PHONE_VALIDATION_MESSAGE);

    newValidations.password = passwrodValidation;
    newValidations.repeatPassword = repeatPasswordValidation;
    newValidations.phone = phoneValidation;
    setValidations(newValidations);

    return checkIfFormIsValid(newValidations);
  };

  const checkIfFormIsValid = (newValidations) => {
    const isFormValid =
        newValidations.repeatPassword.valid &&
        newValidations.password.valid &&
        newValidations.phone.valid;

    setFormHasError(!isFormValid);
    return isFormValid;
  };

  const resetValidationByKey = (key) => {
    const newValidations = { ...validations };
    newValidations[key] = DEFAULT_VALIDATION;
    setValidations(newValidations);
    setFormHasError(false);
    checkIfFormIsComplete(fields);
  };

  const submitForm = () => {
    const isFormValid = validateFields(fields);
    if(isFormValid){
      const requestRegistration = {
        id: requestRegistrationId,
        password: fields.password,
        phoneNumber: fields.phone
      };
      sendRequestRegistration( {
        variables:{
          requestRegistration
        }
      });
    }
  };


  const checkIfFormIsComplete = (newFields) => {
    const isComplete = newFields.phone &&
      newFields.password &&
      newFields.repeatPassword;

    setFormIsComplete(isComplete);
  };

  if(loadingQuery || loadingRequest) return  <LoadingModal />;

  const disableForm = !formIsComplete || formHasError;

  return (
    <Box className={classes.mainContainer} >
      <LogoBanner />
      {
        (userInfoData?.registrationRequest?.name && !errorQuery) ?
          <>
            <ConfirmationUserInfo
              userInfo={userInfoData?.registrationRequest}/>
            <ConfirmationForm
              disableForm={disableForm}
              fields={fields}
              validations={validations}
              submitError={errorRequest}
              onSubmitForm={submitForm}
              onFieldChange={handleFieldChange} />
          </>
          :
          <ConfirmationErrorMessage
            errorTitle={ERROR_TITLE}
            errorMessage={ERROR_MESSAGE} />
      }
    </Box>
  );
};


ConfirmationFormContainer.propTypes = {
  requestRegistrationId: PropTypes.string,
};

ConfirmationFormContainer.defaultProps = {
  requestRegistrationId: ''
};

export default ConfirmationFormContainer;
