import React from 'react';
import { Box } from '@material-ui/core';
import Logo from './logo.component';

const LogoBanner = ({ children, ...otherProps }) => {

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      {...otherProps}>
      <Logo/>
      {children}
    </Box>
  );
};

export default LogoBanner;
