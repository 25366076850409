import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import FlashMessage from '../common/FlashMessage.component';
import PropTypes from 'prop-types';

const useStyle = makeStyles(
  (theme) => ({
    errorContainer: {
      [theme.breakpoints.down('sm')] : {
        padding: `0 ${theme.space.horizontal.mobile}`
      },
      [theme.breakpoints.up('md')] : {
        margin:'auto',
        padding: `${theme.spacing(9)}px ${theme.space.horizontal.desktop}`
      },
    },
  })
);

const ConfirmationErrorMessage = ({
  errorMessage,
  errorTitle
}) => {
  const classes = useStyle();

  return (
    <Box className={classes.errorContainer}>
      <FlashMessage
        fullScreen
        errorMessage={errorMessage}
        errorTitle={errorTitle}
        success={false} />
    </Box>
  );
};

ConfirmationErrorMessage.propTypes = {
  errorMessage : PropTypes.node,
  errorTitle : PropTypes.node,

};

ConfirmationErrorMessage.defaultProps = {
  errorMessage : '',
  errorTitle : '',
};

export default ConfirmationErrorMessage;
