import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Text from '../common/text.component';
import ModalFormButton from '../common/ModalFormButton.component';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LinkExternalPageButton from './LinkExternalPageButton.component';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';


const CONTINUE_BTN_TEXT = 'Continuar navegando';
const ERROR_MESSAGE = '¡Lo sentimos! Algo salió mal, intentá nuevamente más tarde.';
const ERROR_TITLE = 'Ocurrió un error inesperado.';
const SUCCESS_TITLE = 'Operación realizada con éxito.';


const useStyles = makeStyles(theme => ({
  doneContainer: {
    margin: '0px',
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: '100%',
    textAlign:'center',
    paddingTop: theme.spacing(11),
    [theme.breakpoints.down('sm')]:{
      paddingTop: theme.spacing(7.5),
    }
  },
  succesIcon: {
    color: theme.palette.success.main,
    fontSize:'2.5rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize:'2.5rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  doneTitle:{
    display:'block',
    marginBottom: theme.spacing(2)
  },
  doneMessage: {
    display: 'block',
    marginBottom: theme.spacing(4),
    width: '330px'
  },
  button: {
    
  },
}));


const FlashMessage = ({
  onContinue,
  success,
  succesTitle = SUCCESS_TITLE,
  errorTitle = ERROR_TITLE,
  successMessage,
  errorMessage = ERROR_MESSAGE,
  continueButtonText = CONTINUE_BTN_TEXT,
  fullScreen,
  external,
  externalUrl }) => {
  const classes = useStyles();

  const button = external ? <LinkExternalPageButton text_btn={continueButtonText} linkTo={externalUrl} /> : <ModalFormButton
    primaryInverted
    onConfirm={onContinue}
    text={continueButtonText}
    className={classes.button} />;

  return (
    <Box className={classes.doneContainer}>
      { success ?
        <CheckCircleOutlineIcon className={classes.succesIcon}/>
        :
        <CancelOutlinedIcon className={classes.errorIcon}/>
      }
      <Text bold className={classes.doneTitle}>
        { success ? succesTitle : errorTitle }
      </Text>
      <Text light className={classes.doneMessage}>
        { success ? successMessage : errorMessage }
      </Text>
      {!fullScreen &&  
      <Box width={'230px'}>
        {button} 
      </Box>
      }
    </Box>
  );
};

export default FlashMessage;
