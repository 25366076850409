import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import MaskedTextField from '../common/MaskedTextField.component';
import Button from '../common/button.component';
import Text from '../common/text.component';
import PasswordTextField from '../common/PasswordTextField.component';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyle = makeStyles(
  (theme) => ({
    textInput: {
      width:'100%'
    },
    button: {
      marginTop: theme.spacing(2)
    },
    formTitle: {
      textAlign:'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5)
    },
    phone: {
      marginBottom: theme.spacing(2)
    },
    submitErrorMessage: {
      textAlign:'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
  })
);

const PASSWORD_LABEL = 'Contraseña';
const REPEAT_PASSWORD_LABEL = 'Confirmar contraseña';
const REGISTER_BTN_TXT = 'Registrarme';
const PHONE_LABEL = 'Teléfono';
const PHONE_MASK =  new Array(15).fill(/\d/);
const FORM_TITLE = 'Completá los datos solicitados para avanzar';
const SUBMIT_ERROR_MESSAGE = '¡ Lo sentimos ! Ocurrió un error, intentá nuevamente más tarde.';

const ConfirmationForm = ({
  validations,
  fields,
  onFieldChange,
  onSubmitForm,
  submitError,
  disableForm,
}) => {
  const classes = useStyle();

  const handleTextFieldChange = (key, value) => {
    onFieldChange(key, value);
  };

  function handleSubmit(e) {
    e.preventDefault();
    onSubmitForm();
  }

  return (
    <Box>
      <Box className={classes.formTitle}>
        <Text small>{FORM_TITLE}</Text>
      </Box>
      <Box>
        <MaskedTextField
          error={!validations.phone?.valid}
          helperText={validations.phone?.error}
          className={clsx(classes.textInput,classes.phone)}
          data-test-id="input-confirmation-phone"
          label={PHONE_LABEL}
          value={fields.phone}
          onChange={(evt) => handleTextFieldChange('phone' ,evt.target.value)}
          mask={PHONE_MASK}
          variant="outlined"
          tabIndex={3}
        />
        <PasswordTextField
          error={!validations.password?.valid}
          helperText={validations.password?.error}
          FormHelperTextProps={{ component: 'span' }}
          className={classes.textInput}
          data-test-id="input-confirmation-password"
          label={PASSWORD_LABEL}
          value={fields.password}
          onChange={(evt) => handleTextFieldChange('password' ,evt.target.value)}
          variant="outlined"
        />
        <PasswordTextField
          error={!validations.repeatPassword?.valid}
          helperText={validations.repeatPassword?.error}
          className={classes.textInput}
          data-test-id="input-confirmation-repeatPassword"
          label={REPEAT_PASSWORD_LABEL}
          value={fields.repeatPassword}
          onChange={(evt) => handleTextFieldChange('repeatPassword' ,evt.target.value)}
          variant="outlined"
        />
        { submitError &&
        <Box className={classes.submitErrorMessage}>
          <Text warning>{SUBMIT_ERROR_MESSAGE}</Text>
        </Box>
        }
        <Button
          primary
          className={classes.button}
          onClick={handleSubmit}
          fullWidth
          disabled={disableForm}>
          {REGISTER_BTN_TXT}
        </Button>
      </Box>
    </Box>
  );
};

ConfirmationForm.propTypes = {
  validations : PropTypes.object,
  fields : PropTypes.object,
  onFieldChange : PropTypes.func,
  submitError : PropTypes.node,
  onSubmitForm : PropTypes.func,
  disableForm : PropTypes.bool
};

ConfirmationForm.defaultProps = {
  validations : {},
  fields : {},
  onFieldChange :  () => {},
  submitError :  '',
  onSubmitForm :  () => {},
  disableForm : true
};

export default ConfirmationForm;
