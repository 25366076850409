import React from 'react';
import { makeStyles } from '@material-ui/core';
import ModalFormButton from './ModalFormButton.component';

const useStyles = makeStyles(theme => ({
  link: {
    minWidth: '14.563rem',
  },
}));

const LinkExternalPageButton = ({ text_btn, classes_btn, linkTo }) => {
  const classes = useStyles();

  return (
    <a className={classes.link} href={linkTo}>
      <ModalFormButton
        primaryInverted
        text={text_btn}
        className={classes_btn} />
    </a>
  );
};

export default LinkExternalPageButton;
