import React from 'react';
import { Box, Grid,makeStyles } from '@material-ui/core';
import Text from '../common/text.component';
import PropTypes from 'prop-types';

const useStyle = makeStyles(
  (theme) => ({
    infoItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    infoLabel: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
  })
);

const NAME_LABEL = 'Nombre';
const LAST_NAME_LABEL = 'Apellido';
const DNI_LABEL = 'DNI';
const CUIT_CUIL_LABEL = 'Cuit o Cuil';
const EMAIL_LABEL = 'Email';

const ConfirmationUserInfo = ({ userInfo }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <InfoItem
          data-test-id='info-item-name'
          label={NAME_LABEL} value={userInfo.name}/>
      </Grid>
      <Grid item xs={6}>
        <InfoItem
          data-test-id='info-item-lastName'
          label={LAST_NAME_LABEL} value={userInfo.lastName}/>
      </Grid>
      <Grid item xs={6}>
        <InfoItem
          data-test-id='info-item-dni'
          label={DNI_LABEL} value={userInfo.dni}/>
      </Grid>
      <Grid item xs={6}>
        <InfoItem
          data-test-id='info-item-cuil-cuit'
          label={CUIT_CUIL_LABEL} value={userInfo.cuilCuit}/>
      </Grid>
      <Grid item xs={12}>
        <InfoItem
          data-test-id='info-item-email'
          label={EMAIL_LABEL} value={userInfo.email}/>
      </Grid>
    </Grid>
  );
};

const InfoItem = ({ label, value }) => {
  const classes = useStyle();

  return (
    <Box className={classes.infoItem}>
      <Box className={classes.infoLabel}>
        <Text light smaller>{label}</Text>
      </Box>
      <Text little>{value || '--'}</Text>
    </Box>
  );
};


ConfirmationUserInfo.propTypes = {
  userInfo : PropTypes.object,
};

ConfirmationUserInfo.defaultProps = {
  userInfo : {},
};

export default ConfirmationUserInfo;
