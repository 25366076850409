import React from 'react';
import Layout from '../components/layouts/OnboardingLayout.component';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Link from '../components/common/Link.component';
import HomeIcon from '@material-ui/icons/Home';
import Text from '../components/common/text.component';
import ConfirmationFormContainer from '../containers/confirmation-form/ConfirmationForm.container';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
  link: {
    height: '1rem',
  },
  header: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')] : {
      padding: `${theme.spacing(1)} ${theme.space.horizontal.mobile}`,
    },
  },
  loginLeyend: {
    [theme.breakpoints.down('sm')] : {
      display:'none'
    },
  },
  icon: {
    height: '1rem',
    marginBottom: '2px',
    marginRight: '-2px',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  signInLink: {
    paddingLeft: '5px',
  },
}));


const RegisterFormPage = ({ location, pageContext }) => {
  const classes = useStyles();
  const { id } = queryString.parse(location.search);

  return (
    <Layout>
      <Box id="header" className={classes.header}>
        <Box className={classes.link}>
          <Link inverted to='/'>
            <Box display="flex" alignItems="center">
              <HomeIcon className={classes.icon}/>
              <Text smaller>VOLVER</Text>
            </Box>
          </Link>
        </Box>
        <Box>
          <Text light little className={classes.loginLeyend}>¿Ya tenés una cuenta? </Text>
          <Link inverted to='/ingresar/' className={classes.signInLink}>Ingresar</Link>
        </Box>
      </Box>
      <Box className={classes.container}>
        <ConfirmationFormContainer requestRegistrationId={id}/>
      </Box>
    </Layout>
  );
};

export default RegisterFormPage;
