import React from 'react';
import { makeStyles, Box, useMediaQuery,DialogActions } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Button from './button.component';
import Text from './text.component';

const useStyles = makeStyles(theme => ({
  content: {
    minWidth:'60%',
    padding: theme.spacing(2)
  },
  submitError: {
    textAlign: 'center',
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
    minHeight: '3.125rem',
    borderRadius: theme.borderRadius.large,
    justifySelf: 'flex-end',
  },
}));

const ModalFormButton = ({
  text,
  onConfirm,
  disable,
  submitError,
  submitErrorMessage,
  ...btnProps }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      {isDesktop ?
        <FormButton
          submitError={submitError}
          submitErrorMessage={submitErrorMessage}
          disable={disable}
          onConfirm={onConfirm}
          text={text}
          {...btnProps} />
        :
        <DialogActions>
          <FormButton
            submitError={submitError}
            submitErrorMessage={submitErrorMessage}
            disable={disable}
            onConfirm={onConfirm}
            text={text}
            {...btnProps} />
        </DialogActions>
      }
    </>
  );
};

const FormButton = ({
  submitError,
  submitErrorMessage,
  disable,
  onConfirm,
  text,
  ...btnProps }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
      { submitError &&
        <Text warning smaller className={classes.submitError}>
          {submitErrorMessage}
        </Text>
      }
      <Button
        disabled={disable}
        onClick={() => onConfirm()}
        primary
        className={classes.button}
      >
        {text}
      </Button>
    </Box>
  );
};
export default ModalFormButton;
